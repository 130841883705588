@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module StartBlock.module.scss
 *  @since 2023.02.07, 20:35
 *  @changed 2023.03.04, 15:51
 */

.container {
  margin: auto;
  display: flex;
  gap: 20px;
  flex-direction: column-reverse;
  align-items: center;
  @media (min-width: $breakpointsMdPx) {
    gap: 30px;
    flex-direction: row;
    align-items: center;
  }
}

.content {
  text-align: center;
}
.visual {
  /*!KEEP*/
}

.actions,
.question,
.title {
  margin: 10px auto;
}

.error {
  color: $errorColor;
}
