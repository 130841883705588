@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module UserNameDialog.module.scss
 *  @since 2023.02.10, 15:33
 *  @changed 2023.02.10, 15:33
 */

.container {
}

.form {
  padding: 0;
  margin: 0;
}

.dialogTitle {
  // background-color: color.adjust($neutralColor, $alpha: -0.8);
  padding-bottom: 0;
  // margin-bottom: -5px;
}
