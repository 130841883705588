// Generated by next.config from "css-params-source.js"

$gameRedColor: #f1361d;
$gameGreenColor: #aec948;
$primaryColor: #0074eb;
$secondaryColor: #ffa550;
$errorColor: #c33;
$dangerColor: #c33;
$warnColor: #f73;
$successColor: #593;
$infoColor: #0074eb;
$textColor: #000;
$defaultTextColor: #000;
$defaultLinkColor: #003d9d;
$neutralColor: #888;
$bootstrapDarkColor: #212529;
$primaryColors: ( "50": "#ffffff", "100": "#b8dbff", "200": "#52a7ff", "300": "#1f8eff", "400": "#0581ff", "500": "#0074eb", "600": "#0067d1", "700": "#005bb8", "800": "#004285", "900": "#002852", "950": "#000000" );
$primaryColors50: #ffffff;
$primaryColors100: #b8dbff;
$primaryColors200: #52a7ff;
$primaryColors300: #1f8eff;
$primaryColors400: #0581ff;
$primaryColors500: #0074eb;
$primaryColors600: #0067d1;
$primaryColors700: #005bb8;
$primaryColors800: #004285;
$primaryColors900: #002852;
$primaryColors950: #000000;
$secondaryColors: ( "50": "#ffffff", "100": "#ffffff", "200": "#ffd9b6", "300": "#ffbf83", "400": "#ffb269", "500": "#ffa550", "600": "#ff9836", "700": "#ff8b1d", "800": "#e97100", "900": "#b65800", "950": "#502700" );
$secondaryColors50: #ffffff;
$secondaryColors100: #ffffff;
$secondaryColors200: #ffd9b6;
$secondaryColors300: #ffbf83;
$secondaryColors400: #ffb269;
$secondaryColors500: #ffa550;
$secondaryColors600: #ff9836;
$secondaryColors700: #ff8b1d;
$secondaryColors800: #e97100;
$secondaryColors900: #b65800;
$secondaryColors950: #502700;
$neutralColors: ( "50": "#ffffff", "100": "#eeeeee", "200": "#bbbbbb", "300": "#a1a1a1", "400": "#959595", "500": "#888888", "600": "#7b7b7b", "700": "#6e6e6e", "800": "#555555", "900": "#3b3b3b", "950": "#080808" );
$neutralColors50: #ffffff;
$neutralColors100: #eeeeee;
$neutralColors200: #bbbbbb;
$neutralColors300: #a1a1a1;
$neutralColors400: #959595;
$neutralColors500: #888888;
$neutralColors600: #7b7b7b;
$neutralColors700: #6e6e6e;
$neutralColors800: #555555;
$neutralColors900: #3b3b3b;
$neutralColors950: #080808;
$brandColors: ( "primary": "#0074eb", "primary_variant": "#004285", "primary_on": "#1f8eff", "secondary": "#ffa550", "secondary_variant": "#e97100", "secondary_on": "#ffbf83", "background": "#F8FEFC", "background_on": "#003225", "error": "#c33", "error_variant": "#8E001A", "error_on": "#F8FEFC", "actionable": "#1890FF", "warning": "#f73", "dark_green": "#003224" );
$brandColorsPrimary: #0074eb;
$brandColorsPrimary_variant: #004285;
$brandColorsPrimary_on: #1f8eff;
$brandColorsSecondary: #ffa550;
$brandColorsSecondary_variant: #e97100;
$brandColorsSecondary_on: #ffbf83;
$brandColorsBackground: #F8FEFC;
$brandColorsBackground_on: #003225;
$brandColorsError: #c33;
$brandColorsError_variant: #8E001A;
$brandColorsError_on: #F8FEFC;
$brandColorsActionable: #1890FF;
$brandColorsWarning: #f73;
$brandColorsDark_green: #003224;
$bodyBgColor: #f5f5f5;
$titleFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$defaultFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$defaultFontSize: 14;
$defaultFontSizePx: 14px;
$fontSize: 14;
$fontSizePx: 14px;
$fontSizeM: 14;
$fontSizeSm: 12;
$fontSizeSmPx: 12px;
$fontSizeXs: 10;
$fontSizeXsPx: 10px;
$fontSizeLg: 16;
$fontSizeLgPx: 16px;
$fontSizeXl: 18;
$fontSizeXlPx: 18px;
$fontSizeXxl: 22;
$fontSizeXxlPx: 22px;
$titleFontSize: 22;
$titleFontSizePx: 22px;
$defaultLineHeight: 1.3;
$defaultLineHeightPx: 1.3px;
$defaultFontWeight: 400;
$lightFontWeight: 300;
$breakpoints: ( "base": 360, "sm": 512, "md": 768, "lg": 1024, "xl": 1280, "2xl": 1600 );
$breakpointsBase: 360;
$breakpointsBasePx: 360px;
$breakpointsSm: 512;
$breakpointsSmPx: 512px;
$breakpointsMd: 768;
$breakpointsMdPx: 768px;
$breakpointsLg: 1024;
$breakpointsLgPx: 1024px;
$breakpointsXl: 1280;
$breakpointsXlPx: 1280px;
$breakpoints2xl: 1600;
$breakpoints2xlPx: 1600px;
$breakpointsPre: ( "base": 359.98, "sm": 511.98, "md": 767.98, "lg": 1023.98, "xl": 1279.98, "2xl": 1599.98 );
$breakpointsPreBase: 359.98;
$breakpointsPreBasePx: 359.98px;
$breakpointsPreSm: 511.98;
$breakpointsPreSmPx: 511.98px;
$breakpointsPreMd: 767.98;
$breakpointsPreMdPx: 767.98px;
$breakpointsPreLg: 1023.98;
$breakpointsPreLgPx: 1023.98px;
$breakpointsPreXl: 1279.98;
$breakpointsPreXlPx: 1279.98px;
$breakpointsPre2xl: 1599.98;
$breakpointsPre2xlPx: 1599.98px;
$breakpointsPx: ( "base": "360px", "sm": "512px", "md": "768px", "lg": "1024px", "xl": "1280px", "2xl": "1600px" );
$breakpointsPxBase: 360px;
$breakpointsPxSm: 512px;
$breakpointsPxMd: 768px;
$breakpointsPxLg: 1024px;
$breakpointsPxXl: 1280px;
$breakpointsPx2xl: 1600px;
$breakpointsPrePx: ( "base": "359.98px", "sm": "511.98px", "md": "767.98px", "lg": "1023.98px", "xl": "1279.98px", "2xl": "1599.98px" );
$breakpointsPrePxBase: 359.98px;
$breakpointsPrePxSm: 511.98px;
$breakpointsPrePxMd: 767.98px;
$breakpointsPrePxLg: 1023.98px;
$breakpointsPrePxXl: 1279.98px;
$breakpointsPrePx2xl: 1599.98px;
$wrapperMaxWidth: 1280;
$wrapperMaxWidthPx: 1280px;
$innerPadding: 5;
$innerPaddingPx: 5px;
$itemPadding: 10;
$itemPaddingPx: 10px;
$containerPadding: 15;
$containerPaddingPx: 15px;
$blockPadding: 20;
$blockPaddingPx: 20px;
$transitionTime: 250;
$transitionTimeMs: 250ms;
$animationTime: 500;
$animationTimeMs: 500ms;
$disappearTime: 1000;
$disappearTimeMs: 1000ms;
