@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module GameParamsWrapper.module.scss
 *  @since 2023.01.30, 17:58
 *  @changed 2023.02.01, 21:41
 */

.container {
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.contentError {
  color: $errorColor;
  text-align: center;
}

.contentContainer {
  max-width: 100%;
  overflow-x: auto;
}

.smallLoader,
.loaderSplash {
  /*!KEEP*/
}
