@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
.error {
  margin: 10px 0;
}
.wrapper {
  padding: 20px;
}
.content {
  display: flex;
}
